import { Link } from "react-router-dom"
import RouteString from '../.././Lib/RouteString'
import React from 'react';

const MenuPaginate = ({ props }) => {
    return (
        <div role="list" className="grid-4-columns">
            {
                props.paginate.links?.map((e, i) => (
                    (e.label !== 'Next &raquo;' && e.label !== '&laquo; Previous') ?
                        <div role="listitem" className="flex-horizontal flex-vertical-mbl w-dyn-item" key={Math.random()}>
                            <Link
                                to={`${RouteString.menuRoute.route}/page/${e.label}`} className={`badge-secondary category w-button ${(e.active) ? ' w--current' : ''}`}>
                                Trang {e.label}
                            </Link>
                        </div>
                        : ''
                ))
            }
        </div>
    )
}

export default MenuPaginate