import Logo from '../../Layouts/Logo'
import Footer from '../../Layouts/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderDetail } from '../../Store/Order/slice'
import { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { CurrentcyConvertVND, DateTimeFromTimestamp } from '../../Lib/DateConvert'
import { useNavigate, useParams } from 'react-router-dom'
import RouteString from '../../Lib/RouteString'
import Skeleton from 'react-loading-skeleton'
import CreateOrderFoodList from './CreateOrderFoodList'
import React from 'react';

const CreateOrderResultPage = () => {
    const dispatch = useDispatch()
    const [orderResult, setOrderResult] = useState(null)
    const [cart, setCart] = useState(null)
    const [cartPrice, setCartPrice] = useState(0)
    const { getOrderDetailResult } = useSelector(state => state.order)
    const customerInfo = JSON.parse(Cookies.get('user'))
    const navigate = useNavigate()
    const { orderID } = useParams()
    useEffect(() => {
        dispatch(getOrderDetail(orderID))
        document.title = 'Đơn đặt món ăn - Quán ăn Cường Loan'
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (getOrderDetailResult !== undefined) {
            if (getOrderDetailResult.success) {
                setOrderResult(getOrderDetailResult.order)
                setCart(JSON.parse(getOrderDetailResult.order.OrderDetail))
            } else
                setOrderResult(false)
        }

    }, [getOrderDetailResult])
    useEffect(() => {
        if (cart?.length > 0) {
            setCartPrice(0)
            let price = 0
            cart.forEach(e => {
                price += e.Price * e.Count
            });
            setCartPrice(price)
        }
    }, [cart])
    return (
        <div style={{ opacity: 1 }} className="page-wrapper">
            <Logo />
            <div className="inner-container main center-element">
                <div className="card main">
                    <div className="section wf-section">
                        <div className="container-default w-container">
                            <div className="inner-container _500px-mbl center-element">
                                <div className="inner-container _710px center-element">
                                    {
                                        orderResult === null ?
                                            <Skeleton height={39} count={6} />
                                            :
                                            !orderResult ?
                                                <div className="utility-page-content w-form">
                                                    <h1 className="heading-h2-size mg-bottom-16px">Không tìm thấy đơn này của Quý Khách!</h1>
                                                    <p className="mg-bottom-28px">
                                                        Quán hiện tại không thể tìm được đơn đặt món này của Quý Khách, kính mời Quý Khách vui lòng quay lại để xem tất cả đơn đặt món ăn của mình!
                                                    </p>
                                                    <div className="buttons-row center">
                                                        <button className="btn-primary w-button"
                                                            onClick={() => navigate(RouteString.homeRoute.route)}
                                                        >
                                                            Trở về trang chủ
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <h1 className="heading-h2-size mg-bottom-16px">Quý Khách đã đặt món ăn</h1>
                                                    <p className="mg-bottom-28px">
                                                        Quán ăn Cường Loan xin cảm ơn Quý Khách đã đặt món ăn, quán sẽ liên hệ với Quý Khách sớm nhất có thể.
                                                    </p>
                                                    <div className="text-uppercase">Mã đơn: <div className="badge-secondary small light">{orderResult.OrderID}</div></div>
                                                    <div className="text-uppercase">Ngày đặt: {DateTimeFromTimestamp(orderResult.OrderTime)}</div>
                                                    <div className="text-uppercase">Người đặt: {customerInfo.Gender ? 'Anh' : 'Chị'} {customerInfo.Name}</div>
                                                    <div className="text-uppercase">Địa chỉ: {customerInfo.Address}</div>
                                                    <div className="text-uppercase">Số điện thoại: 0{customerInfo.Phone}</div>
                                                    <div className="text-uppercase">Trạng thái: <div className="badge-primary small light">{orderResult.OrderState}</div></div>
                                                    <div className="text-uppercase">Chi tiết món ăn: </div>
                                                    <CreateOrderFoodList
                                                        props={{
                                                            cart,
                                                            customerInfo
                                                        }}
                                                    />
                                                    <h5>Tổng thành tiền: <b>{CurrentcyConvertVND(cartPrice)}</b></h5>
                                                    <div className="buttons-row center">
                                                        <button className="btn-primary w-button"
                                                            onClick={() => navigate(RouteString.orderListRoute.route)}
                                                        >
                                                            Danh sách đơn đặt món
                                                        </button>
                                                    </div>
                                                </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="loading-bar-wrapper">
                <div style={{ width: '0vw' }} className="loading-bar"></div>
            </div>
            <Footer />
        </div>
    )
}

export default CreateOrderResultPage