import { Link } from "react-router-dom"
import { CurrentcyConvertVND } from '../../Lib/DateConvert'
import React from 'react';

const MenuFoodList = ({ props }) => {
    return (
        (props.foodList.length !== 0) ?
            <div role="list" className="grid-1-column gap-row-24px gap-row-80px-mbl w-dyn-items">
                {
                    props.foodList.map((e) => (
                        <div role="listitem" className="w-dyn-item" key={e.Slug}>
                            <div className="flex-horizontal align-center flex-vertical-mbl menu">
                                <div className="mg-right-35px mg-right-0px-mbl mg-bottom-44px-mbl">
                                    <div className="inner-container _240px max-w-100-mbl">
                                        <Link to={`/food/${e.Slug}`} className="image-wrapper border-radius-16px w-inline-block">
                                            <img crossOrigin="anonymouse" src={e.Image[0].image.IMAGEURL}
                                                loading="eager" alt="Classic Burger" className="image cover" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="inner-container _430px max-w-100-mbl">
                                    <Link to={`/food/${e.Slug}`}
                                        className="link-content w-inline-block">
                                        <div className="mg-bottom-11px">
                                            <div className="flex-horizontal space-between align-center children-wrap">
                                                <div className="mg-right-16px">
                                                    <h2 className="heading-h3-size mg-bottom-0">{e.Name}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-layout-grid grid-4-columns menu-options">
                                            {
                                                // e.Variant.find(variant => variant. === targetPriceId)
                                                e.Variant.map((t) => (
                                                    (t.ISSHOW) ?
                                                        <div className="badge-primary small" key={t.PRICEID}>
                                                            <div className="flex-horizontal align-center">
                                                                <div className="text-100 semi-bold text-size-16px">{CurrentcyConvertVND(t.PRICEVALUE)}</div>
                                                            </div>
                                                        </div>
                                                        :
                                                        ''
                                                ))
                                            }
                                        </div>
                                        <p className="mg-bottom-32px mg-bottom-18px-mbl">{e.Description}</p>
                                    </Link>
                                    <div className="flex-horizontal flex-vertical-mbp">
                                        <button className="w-commerce-commerceaddtocartbutton btn-primary small add-cart"
                                            onClick={() => props.setFoodToCart(e)}
                                        >
                                            Chọn món {e.Name}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div role="navigation" aria-label="List" className="w-pagination-wrapper pagination"></div>
                        </div>
                    ))
                }
            </div>
            :
            <div className="w-dyn-list">
                <div className="empty-state w-dyn-empty">
                    <div>Quán chưa phục vụ món ăn loại này, mong Quý Khách thông cảm!</div>
                </div>
                <div role="navigation" aria-label="List" className="w-pagination-wrapper pagination">
                </div>
            </div>
    )
}

export default MenuFoodList