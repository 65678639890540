import React from 'react';

const RouteString = {
    // Auth route
    registerRoute: {
        route: '/register',
        txt: 'Đăng ký'
    },
    loginRoute: {
        route: '/login',
        txt: 'Đăng nhập'
    },
    homeRoute: {
        route: '/',
        txt: 'Trang chủ'
    },
    menuRoute: {
        route: '/menu',
        txt: 'Thực đơn'
    },
    menuPaginateRoute: {
        route: '/menu/page/:page',
        txt: 'Thực đơn'
    },
    menuFoodTypeRoute: {
        route: '/menu/:foodTypeParam',
        txt: 'Thực đơn'
    },
    foodTypeDetailRoute: {
        route: '/food-types/:foodTypeSlug',
        txt: 'Chi tiết loại món ăn'
    },
    foodTypeRoute: {
        route: '/food-types',
        txt: 'Loại món ăn'
    },
    foodUpdateRoute: {
        route: '/update-food/:foodSlug',
        txt: 'Cập nhật món ăn'
    },
    foodDetailRoute: {
        route: '/food/:foodSlug',
        txt: 'Chi tiết món ăn'
    },
    foodRoute: {
        route: '/foods',
        txt: 'Món ăn'
    },
    foodAddRoute: {
        route: '/add-food',
        txt: 'Thêm món ăn'
    },
    cartRoute: {
        route: '/cart',
        txt: 'Giỏ món ăn'
    },
    createOrderRoute: {
        route: '/order-food',
        txt: 'Đặt món ăn'
    },
    orderResultRoute: {
        route: '/order-result/:orderID',
        txt: 'Kết quả đặt món ăn'
    },
    orderListRoute: {
        route: '/order-list',
        txt: 'Danh sách các đơn đã đặt'
    },
    templateMenuRoute: {
        route: '/party-menu',
        txt: 'Mẫu menu đặt tiệc'
    },
    templateMenuListRoute: {
        route: '/menu-type/:menuType',
        txt: 'Mẫu menu đặt tiệc'
    },
    templateMenuDetailRoute: {
        route: '/party-menu/:menuID',
        txt: 'Chi tiết mẫu menu đặt tiệc'
    },
}

export default RouteString