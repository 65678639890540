import Logo from '../../Layouts/Logo'
import Footer from '../../Layouts/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { getOrders } from '../../Store/Order/slice'
import { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { CurrentcyConvertVND, DateTimeFromTimestamp } from '../../Lib/DateConvert'
import { useNavigate } from 'react-router-dom'
import RouteString from '../../Lib/RouteString'
import Skeleton from 'react-loading-skeleton'
import React from 'react';

const OrderListPage = () => {
    const dispatch = useDispatch()
    const [orderList, setOrderList] = useState(null)
    const { getOrdersResult } = useSelector(state => state.order)
    const customerInfo = JSON.parse(Cookies.get('user'))
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getOrders())
        document.title = 'Danh sách đơn đặt món - Quán ăn Cường Loan'
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (getOrdersResult !== undefined) {
            if (getOrdersResult.success) {
                setOrderList(getOrdersResult.orders)
            } else
                setOrderList(false)
        }

    }, [getOrdersResult])

    return (
        <div style={{ opacity: 1 }} className="page-wrapper">
            <Logo />
            <div className="inner-container main center-element">
                <div className="card main">
                    <div className="section wf-section">
                        <div className="container-default w-container">
                            <div className="inner-container _500px-mbl center-element">
                                <div className="inner-container _710px center-element">
                                    {
                                        orderList === null ?
                                            <Skeleton height={39} count={6} />
                                            :
                                            (orderList.lenght == 0) ?
                                                <div className="utility-page-content w-form">
                                                    <h1 className="heading-h2-size mg-bottom-16px">Quý Khách chưa có đơn đặt món ăn nào!</h1>
                                                    <p className="mg-bottom-28px">
                                                        Quán hiện tại không thể tìm được đơn đặt món nào của Quý Khách, mời Quý Khách đặt và thưởng thức các món ăn của quán!
                                                    </p>
                                                    <div className="buttons-row center">
                                                        <button className="btn-primary w-button"
                                                            onClick={() => navigate(RouteString.homeRoute.route)}
                                                        >
                                                            Trở về trang chủ
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <h1 className="heading-h2-size mg-bottom-16px">Danh sách các đơn đặt món ăn của {(customerInfo.Gender ? 'Anh' : 'Chị')} {customerInfo.Name}:</h1>
                                                    <p className="mg-bottom-28px">
                                                    </p>
                                                    {
                                                        orderList.map(e => {
                                                            let order = JSON.parse(e)
                                                            let orderDetail = JSON.parse(order.OrderDetail)
                                                            let orderTotal = orderDetail.reduce((total, item) => total + (item.Price * item.Count), 0)
                                                            return (
                                                                <div key={order.OrderID} >
                                                                    <div className="flex-horizontal space-between align-center flex-vertical-mbl">
                                                                        <div className="image-wrapper border-radius-16px location"
                                                                        >
                                                                            <img
                                                                                crossOrigin="anonymouse"
                                                                                src={orderDetail[0].Image[0]}
                                                                                loading="eager" alt={e.Name} className="image cover"
                                                                            />
                                                                        </div>
                                                                        <div className="inner-container _330px width-100 max-w-100-mbl"
                                                                            onClick={() => navigate(`/order-result/${order.OrderID}`)}
                                                                        >
                                                                            <p>
                                                                                {e.Variant}
                                                                                <br />
                                                                                Mã đơn đặt món: <b>{order.OrderID}</b>
                                                                                <br />
                                                                                Ngày đặt: <b>{DateTimeFromTimestamp(order.OrderTime)}</b>
                                                                                <br />
                                                                                Số lượng món ăn: <b>{orderDetail.length}</b>
                                                                                <br />
                                                                                Trạng thái: <div className="badge-primary small light">{order.OrderState}</div>
                                                                            </p>
                                                                            <p>Thành tiền: <b>{CurrentcyConvertVND(orderTotal)}</b></p>
                                                                            <div className="divider _20px"></div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                    <div className="buttons-row center">
                                                        <button className="btn-primary w-button"
                                                            onClick={() => navigate(RouteString.homeRoute.route)}
                                                        >
                                                            Trở về trang chủ
                                                        </button>
                                                    </div>
                                                </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="loading-bar-wrapper">
                <div style={{ width: '0vw' }} className="loading-bar"></div>
            </div>
            <Footer />
        </div >
    )
}

export default OrderListPage