/** @format */
import React from 'react';

import instance from '.';

export const addFoodToCartAPI = async (payload) => {
    return await instance.request({
        method: "POST",
        url: `/add-food-to-cart`,
        data: payload
    });
};

export const getCartAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/get-cart`,
    });
};

export const increasingFoodOfCartAPI = async (payload) => {
    return await instance.request({
        method: "PUT",
        url: `/increasing-food-of-cart`,
        data: payload
    });
};


export const decreasingFoodOfCartAPI = async (payload) => {
    return await instance.request({
        method: "PUT",
        url: `/decreasing-food-of-cart`,
        data: payload
    });
};

export const deletingFoodFromCartAPI = async (payload) => {
    return await instance.request({
        method: "DELETE",
        url: `/deleting-food-from-cart`,
        data: payload
    });
};
