import Logo from '../../Layouts/Logo'
import Footer from '../../Layouts/Footer'
import { useEffect, useState } from 'react'
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMenuDetail } from '../../Store/Food/slice'
import { useParams } from 'react-router-dom';
const MenuPartyDetailPage = () => {
    const dispatch = useDispatch()
    const { getMenuDetailResult } = useSelector(state => state.food)
    const [menuDetail, setMenuDetail] = useState({
        MenuID: 1,
        MenuName: "Đang tải",
        MenuType: "Đang tải",
        MenuTypeID: 1,
        MenuDetail: []
    })
    const [menuDetailFood, setMenuDetailFood] = useState([])

    const { menuID } = useParams()
    useEffect(() => {
        dispatch(getMenuDetail(menuID))
        document.title = 'Mẫu menu đặt tiệc - Quán ăn Cường Loan'
        window.scrollTo(0, 0);
    }, [])
    useEffect(() => {
        if (getMenuDetailResult !== undefined && getMenuDetailResult.success) {
            setMenuDetail(getMenuDetailResult.menu)
            setMenuDetailFood(JSON.parse(getMenuDetailResult.menu.MenuDetail))
        }
    }, [getMenuDetailResult])
    return (
        <div style={{ opacity: 1 }} className="page-wrapper">
            <Logo />
            <div className="inner-container main center-element">
                <div className="card main">
                    <div className="section">
                        <div className="container-default w-container">
                            <div className="inner-container _500px-mbl center-element">
                                <div className="inner-container _710px center-element">
                                    <h2 className="text-center mg-bottom-32px">{menuDetail.MenuName}</h2>
                                    <div className="w-layout-grid grid-1-column gap-row-40px gap-row-64px-mbl">
                                        {
                                            menuDetailFood.length > 0 ?
                                                menuDetailFood.map((e) => (
                                                    <div className="flex-horizontal space-between align-center flex-vertical-mbl" key={e.ID}>
                                                        <div className="image-wrapper border-radius-16px location">
                                                            <img src={e.Image[0]} crossOrigin='anonymouse' loading="eager" alt={e.Name} className="image cover" />
                                                        </div>
                                                        <div className="inner-container _330px width-100 max-w-100-mbl">
                                                            <h3 className="mg-bottom-6px">{e.Name}</h3>
                                                            <div className="divider _20px"></div>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <></>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="loading-bar-wrapper">
                <div style={{ width: '0vw' }} className="loading-bar"></div>
            </div>
            <Footer />
        </div >
    )
}

export default MenuPartyDetailPage