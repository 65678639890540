import Logo from '../../Layouts/Logo'
import Footer from '../../Layouts/Footer'
import { useEffect, useState } from 'react'
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMenus } from '../../Store/Food/slice'
import { useParams } from 'react-router-dom';
const MenuPartyListPage = () => {
    const dispatch = useDispatch()
    const { getMenusResult } = useSelector(state => state.food)
    const [menuList, setMenuList] = useState([])
    const { menuType } = useParams()
    useEffect(() => {
        dispatch(getMenus())
        document.title = 'Mẫu menu đặt tiệc - Quán ăn Cường Loan'
        window.scrollTo(0, 0);
    }, [])
    useEffect(() => {
        if (getMenusResult !== undefined) {
            if (menuType == 1)
                setMenuList(getMenusResult.menus)
            else if (menuType == 2)
                setMenuList(getMenusResult.weddingMenus)
        }
    }, [getMenusResult])
    return (
        <div style={{ opacity: 1 }} className="page-wrapper">
            <Logo />
            <div className="inner-container main center-element">
                <div className="card main">
                    <div className="section bg-accent-1">
                        <div className="container-default w-container">
                            <div className="inner-container _710px center-element">
                                <div className="text-center">
                                    <h1 className="color-neutral-100 mg-bottom-8px">Menu mẫu đặt tiệc</h1>
                                    <p className="color-neutral-200">Mời Quý khách tham khảo một số mẫu menu quán thường phục vụ tiệc
                                    </p>
                                </div>
                                <div className="buttons-row center mg-top-40px">
                                    <div className="w-layout-grid grid-3-columns gap-row-80px">
                                        {
                                            menuList.map((e, i) => (
                                                <div className="style---shadow-card button-shadow-color-01" key={e.MENUID} onClick={() => window.location = `/party-menu/${e.MENUID}`}>
                                                    <div className="text-400 extra-bold color-neutral-800" >{e.MENUNAME}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="loading-bar-wrapper">
                <div style={{ width: '0vw' }} className="loading-bar"></div>
            </div>
            <Footer />
        </div >
    )
}

export default MenuPartyListPage