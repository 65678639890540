/** @format */
import React from 'react';

import instance from '.';

export const getAllFoodTypesAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/get-food-types`,
    });
};

export const getAllFoodsAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/get-foods?page=${payload}`,
    });
};

export const getAllFoodsByFoodtypeAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/get-foods-by-foodtype/${payload}`,
    });
};

export const getFoodDetailAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/get-food-detail/${payload}`,
    });
};

export const getMenusAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/menus`,
    });
};

export const getMenuDetailAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/menu/${payload}`,
    });
};

