import Logo from '../../Layouts/Logo'
import Footer from '../../Layouts/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { getCart } from '../../Store/Cart/slice'
import { createOrder } from '../../Store/Order/slice'
import { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import CreateOrderFoodList from './CreateOrderFoodList'
import { CurrentcyConvertVND } from '../../Lib/DateConvert'
import { useNavigate } from 'react-router-dom'
import RouteString from '../../Lib/RouteString'
import { toast } from 'react-hot-toast'
import React from 'react';

const CreateOrderPage = () => {
    const dispatch = useDispatch()
    const [cart, setCart] = useState(null)
    const [cartPrice, setCartPrice] = useState(0)
    const { getCartResult } = useSelector(state => state.cart)
    const { createOrderResult } = useSelector(state => state.order)
    const customerInfo = JSON.parse(Cookies.get('user'))
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getCart())
        document.title = 'Đặt món ăn - Quán ăn Cường Loan'
        window.scrollTo(0, 0);
    }, [])
    useEffect(() => {
        if (getCartResult !== undefined)
            if (getCartResult.success) {
                setCart(JSON.parse(getCartResult.cart))
            }
    }, [getCartResult])
    useEffect(() => {
        if (getCartResult !== undefined)
            if (getCartResult.success) {
                setCart(JSON.parse(getCartResult.cart))

            }
    }, [getCartResult])
    useEffect(() => {
        if (cart?.length > 0) {
            setCartPrice(0)
            let price = 0
            cart.forEach(e => {
                price += e.Price * e.Count
            });
            setCartPrice(price)
        }
    }, [cart])

    useEffect(() => {
        if (createOrderResult !== undefined) {
            toast.remove()
            if (createOrderResult.success) {
                toast.success(createOrderResult.message)
                navigate(`/order-result/${createOrderResult.order.OrderID}`)
            } else {
                toast.error(createOrderResult.message)
            }
        }

    }, [createOrderResult])
    const goToCart = () => {
        navigate(RouteString.cartRoute.route)
    }
    const createOrderHandle = () => {
        dispatch(createOrder())
    }
    return (
        <div style={{ opacity: 1 }} className="page-wrapper">
            <Logo />
            <div className="inner-container main center-element">
                <div className="card main mg-bottom-64px">
                    <div className="section wf-section">
                        <div className="container-default w-container">
                            <div className="inner-container _500px-mbl center-element">
                                <div className="inner-container _710px center-element">
                                    <h2 className="text-center mg-bottom-32px">Thông tin của {customerInfo.Gender ? 'anh' : 'chị'} {customerInfo.Name}</h2>
                                    <div className="w-layout-grid grid-1-column gap-row-40px gap-row-64px-mbl">
                                        <p>Địa chỉ: {customerInfo.Address}<hr />
                                            Điện thoại liên lạc: 0{customerInfo.Phone}</p>

                                    </div>
                                    <hr />
                                    <h2 className="text-center mg-bottom-32px">Các món ăn {customerInfo.Gender ? 'anh' : 'chị'} {customerInfo.Name} đã chọn</h2>
                                    {
                                        <CreateOrderFoodList
                                            props={{
                                                cart,
                                                customerInfo
                                            }}
                                        />
                                    }


                                    {
                                        cart?.length != 0 ?
                                            <>
                                                <h5>Tổng thành tiền: <b>{CurrentcyConvertVND(cartPrice)}</b></h5>
                                                <div className="buttons-row">
                                                    <button className="btn-secondary width-100 w-button"
                                                        onClick={() => goToCart()}
                                                    >
                                                        Điều chỉnh món ăn
                                                    </button>
                                                    <button className="btn-primary width-100 w-button"
                                                        onClick={() => createOrderHandle()}
                                                    >
                                                        Xác nhận đặt
                                                    </button>
                                                </div>
                                            </>
                                            :
                                            ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="loading-bar-wrapper">
                <div style={{ width: '0vw' }} className="loading-bar"></div>
            </div>
            <Footer />
        </div>
    )
}

export default CreateOrderPage