/** @format */

import { takeLatest } from "redux-saga/effects";
import {
  handleCreateOrderAPI,
  handleGetOrderDetailAPI,
  handleGetOrdersAPI
} from "./handlers";
import {
  createOrder,
  getOrderDetail,
  getOrders
} from "./slice";

export default function* ordersWatcher() {
  yield takeLatest(createOrder.type, handleCreateOrderAPI);
  yield takeLatest(getOrderDetail.type, handleGetOrderDetailAPI);
  yield takeLatest(getOrders.type, handleGetOrdersAPI);
}
