// import postSlice from './Post/slice'
// import courseSlice from './Course/slice'
import authSlice from './Auth/slice'
import foodSlice from './Food/slice'
import cartSlice from './Cart/slice'
import orderSlice from './Order/slice'
import { combineReducers } from '@reduxjs/toolkit';

export const reducer = combineReducers({
    auth: authSlice,
    food: foodSlice,
    cart: cartSlice,
    order: orderSlice,
});
