import { Link } from 'react-router-dom'
import LogoSpoon from '../Assets/image/LogoSpoon.svg'
import RouteString from '../Lib/RouteString'
import React from 'react';

const Header = () => {
    return (
        <div className="section pd-top-0px wf-section">
            <div className="image-wrapper small-cover"><img
                src="https://assets.website-files.com/61e0a7ab0e57e953835f3aeb/61e0d026cf8084cdb6cd1e27_image-cover-home-qrcode-template.jpeg"
                loading="eager" sizes="(max-width: 767px) 100vw, (max-width: 991px) 93vw, 838px"
                srcSet="https://assets.website-files.com/61e0a7ab0e57e953835f3aeb/61e0d026cf8084cdb6cd1e27_image-cover-home-qrcode-template-p-500.jpeg 500w, https://assets.website-files.com/61e0a7ab0e57e953835f3aeb/61e0d026cf8084cdb6cd1e27_image-cover-home-qrcode-template-p-800.jpeg 800w, https://assets.website-files.com/61e0a7ab0e57e953835f3aeb/61e0d026cf8084cdb6cd1e27_image-cover-home-qrcode-template-p-1600.jpeg 1600w, https://assets.website-files.com/61e0a7ab0e57e953835f3aeb/61e0d026cf8084cdb6cd1e27_image-cover-home-qrcode-template.jpeg 1680w"
                alt="Quán ăn Cường Loan" className="image cover" /></div>
            <div className="container-default w-container">
                <div className="mg-top--124px">
                    <div className="flex-horizontal justify-center mg-bottom-30px"><img
                        src={LogoSpoon}
                        loading="eager" width="70"
                        alt="Quán ăn Cường Loan"
                        className="avatar-circle _07 borders" /></div>
                    <div className="inner-container _710px center-element">
                        <div className="text-center">
                            <h1>Quán ăn Cường Loan</h1>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15735.35813264839!2d105.95628037730708!3d9.60908203991653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31a0520024c72a0f%3A0x78f895175b2bd2c5!2zUXXDoW4gxINuIEPGsOG7nW5nIExvYW4!5e0!3m2!1sen!2s!4v1588323327276!5m2!1sen!2s" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" style={{ width: '100%', height: '360px' }}></iframe>

                        </div>
                        <p className="paragraph-large  mg-top-40px" style={{ textAlign: 'justify' }}>Kính chào Quý khách, website Quán ăn Cường Loan phục vụ cho việc tham khảo các món ăn và các mẫu menu để Quý khách đặt tiệc được dễ dàng hơn. Quán sẽ luôn cập nhật các món ăn mới để Quý khách có thêm nhiều sự lựa chọn.</p>
                        <div className="buttons-row center mg-top-40px">
                            <div className="button-row-first"><Link to={RouteString.templateMenuRoute.route}
                                className="btn-primary w-button">Xem menu mẫu</Link></div>
                            <div className="button-row-last"><Link to={RouteString.menuRoute.route} className="btn-secondary w-button">Xem menu món ăn</Link></div>
                        </div>

                        <p className="paragraph-large  mg-top-40px" style={{ textAlign: 'justify' }}>Để được tư vấn ngay, Quý khách có thể liên lạc theo các địa chỉ liên hệ bên dưới.</p>
                        <p className="paragraph-large  mg-top-40px" style={{ textAlign: 'center' }}>101/17 Hùng Vương, Phường 6, TP.Sóc Trăng</p>
                        <div className="w-layout-grid grid-1-column">

                            <a href="tel:02993826498" className="card-link-icon w-inline-block"><img
                                src="https://assets.website-files.com/61e0a7ab0e57e953835f3aeb/61e1db5777c6d866068825db_icon-1-footer-contact-qrcode-template.svg"
                                alt="Điện thoại của quán ăn Cường Loan"
                                className="square-icon mg-right-16px mg-bottom-16px-mb" />
                                <div>
                                    <div className="text-200 color-neutral-600 mg-bottom-8px">Điện thoại:</div>
                                    <div className="text-200 extra-bold">0299.3826498</div>
                                </div>
                            </a>
                            <a href="tel:0918084371" className="card-link-icon w-inline-block"><img
                                src="https://assets.website-files.com/61e0a7ab0e57e953835f3aeb/61e1db5777c6d866068825db_icon-1-footer-contact-qrcode-template.svg"
                                alt="Điện thoại của quán ăn Cường Loan"
                                className="square-icon mg-right-16px mg-bottom-16px-mb" />
                                <div>
                                    <div className="text-200 color-neutral-600 mg-bottom-8px">Điện thoại:</div>
                                    <div className="text-200 extra-bold">0918.084371</div>
                                </div>
                            </a>
                            <a href="tel:0828084371" className="card-link-icon w-inline-block"><img
                                src="https://assets.website-files.com/61e0a7ab0e57e953835f3aeb/61e1db5777c6d866068825db_icon-1-footer-contact-qrcode-template.svg"
                                alt="Điện thoại của quán ăn Cường Loan"
                                className="square-icon mg-right-16px mg-bottom-16px-mb" />
                                <div>
                                    <div className="text-200 color-neutral-600 mg-bottom-8px">Điện thoại:</div>
                                    <div className="text-200 extra-bold">0828.084371</div>
                                </div>
                            </a>
                            <a href="mailto:quanancuongloan@gmail.com"
                                className="card-link-icon w-inline-block"><img
                                    src="https://assets.website-files.com/61e0a7ab0e57e953835f3aeb/61e1db57ba93c3247b488c4e_icon-2-footer-contact-qrcode-template.svg"
                                    alt="Email của quán ăn Cường Loan"
                                    className="square-icon mg-right-16px mg-bottom-16px-mb" />
                                <div>
                                    <div className="text-200 color-neutral-600 mg-bottom-8px">Email:</div>
                                    <div className="text-200 extra-bold">quanancuongloan@gmail.com</div>
                                </div>
                            </a>
                            <p className="paragraph-large  mg-top-40px" style={{ textAlign: 'center' }}>Quán ăn Cường Loan, trân trọng kính mời!</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header