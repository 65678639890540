import Skeleton from "react-loading-skeleton"
import { CurrentcyConvertVND } from '../../Lib/DateConvert'
import { useNavigate } from "react-router-dom"
import React from 'react';

const CreateOrderFoodList = ({ props }) => {
    const navigate = useNavigate()
    return (
        props.cart === null ?
            <Skeleton count={5} height={50} />
            :
            <div className="w-layout-grid grid-1-column gap-row-40px gap-row-64px-mbl">
                {
                    props.cart?.length === 0 ?
                        <div className="w-dyn-list">
                            <div className="empty-state w-dyn-empty">
                                <div>
                                    {props.customerInfo.Gender ? 'Anh' : 'Chị'} {props.customerInfo.Name} chưa chọn món ăn nào!
                                </div>
                                <div>
                                    {props.customerInfo.Gender ? 'Anh' : 'Chị'} {props.customerInfo.Name} hãy vào xem menu của quán để tham khảo các món ăn và lựa chọn!
                                </div>
                            </div>
                            <div role="navigation" aria-label="List" className="w-pagination-wrapper pagination">
                            </div>
                        </div>
                        :
                        props.cart.map((e) => (
                            <div key={e.PriceID} className="flex-horizontal space-between align-center flex-vertical-mbl">
                                <div className="image-wrapper border-radius-16px location"
                                    onClick={() => navigate(`/food/${e.Slug}`)}
                                >
                                    <img
                                        crossOrigin="anonymouse"
                                        src={e.Image[0]}
                                        loading="eager" alt={e.Name} className="image cover"
                                    />
                                </div>
                                <div className="inner-container _330px width-100 max-w-100-mbl">
                                    <h3 className="mg-bottom-6px"
                                        onClick={() => navigate(`/food/${e.Slug}`)}
                                    >
                                        {e.Name}
                                    </h3>
                                    <p>
                                        {e.Variant}
                                        <br />
                                        Đơn giá: <b>{CurrentcyConvertVND(e.Price)}</b>
                                        <br />
                                        Số lượng: <b>{e.Count}</b>
                                    </p>
                                    <p>Thành tiền: <b>{CurrentcyConvertVND(e.Price * e.Count)}</b></p>
                                    <div className="divider _20px"></div>

                                </div>
                            </div>
                        ))
                }
            </div >
    )
}

export default CreateOrderFoodList