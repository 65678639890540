/** @format */

import {
    addFoodToCartAPI,
    getCartAPI,
    decreasingFoodOfCartAPI,
    increasingFoodOfCartAPI,
    deletingFoodFromCartAPI
} from '../../API/Cart';
import { call, put } from "redux-saga/effects";
import {
    addFoodToCartResult,
    getCartResult,
    decreasingFoodOfCartResult,
    increasingFoodOfCartResult,
    deletingFoodFromCartResult
} from "./slice";

function* handleDeletingFoodFromCartAPI({ payload }) {
    try {
        const response = yield call(deletingFoodFromCartAPI, payload);
        if (response.status === 201) {
            yield put(deletingFoodFromCartResult(response.data));
        }
    } catch (error) {
        yield put(deletingFoodFromCartResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleIncreasingFoodOfCartAPI({ payload }) {
    try {
        const response = yield call(increasingFoodOfCartAPI, payload);
        if (response.status === 201) {
            yield put(increasingFoodOfCartResult(response.data));
        }
    } catch (error) {
        yield put(increasingFoodOfCartResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleDecreasingFoodOfCartAPI({ payload }) {
    try {
        const response = yield call(decreasingFoodOfCartAPI, payload);
        if (response.status === 201) {
            yield put(decreasingFoodOfCartResult(response.data));
        }
    } catch (error) {
        yield put(decreasingFoodOfCartResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleAddFoodToCartAPIAPI({ payload }) {
    try {
        const response = yield call(addFoodToCartAPI, payload);
        if (response.status === 201) {
            yield put(addFoodToCartResult(response.data));
        }
    } catch (error) {
        yield put(addFoodToCartResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetCartAPI({ payload }) {
    try {
        const response = yield call(getCartAPI, payload);
        if (response.status === 201) {
            yield put(getCartResult(response.data));
        }
    } catch (error) {
        yield put(getCartResult({ success: false, code: Math.random(1000) }));
    }
}

export {
    handleAddFoodToCartAPIAPI,
    handleGetCartAPI,
    handleDecreasingFoodOfCartAPI,
    handleIncreasingFoodOfCartAPI,
    handleDeletingFoodFromCartAPI
};
