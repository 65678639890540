/** @format */

import { all, fork } from "redux-saga/effects";
import authsWatcher from "./Auth/saga";
import foodsWatcher from "./Food/saga";
import cartsWatcher from "./Cart/saga";
import ordersWatcher from "./Order/saga";

export default function* rootSaga() {
  yield all([
    fork(authsWatcher),
    fork(foodsWatcher),
    fork(cartsWatcher),
    fork(ordersWatcher),
  ]);
}
