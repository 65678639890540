import './App.css';
import { useEffect, useState } from "react"
import { RouteController } from "./Route/RouteController"
import { AuthContext } from "./Context/ContextConfig"
import Cookies from 'js-cookie';
import React from 'react';
const App = () => {
  const [token, setToken] = useState(Cookies.get('token'))
  const [user, setUser] = useState(Cookies.get('user'))
  const setLogin = (token, user) => {
    if (token != null)
      setToken(token)
    if (user != null)
      setUser(user)
  }
  useEffect(() => {

  })
  return (
    <AuthContext.Provider
      value={
        {
          token: token || null,
          user: user || null,
          setLogin
        }
      }
    >
      <RouteController />
    </AuthContext.Provider>

  );
}

export default App;
