import Logo from '../../Layouts/Logo'
import Footer from '../../Layouts/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { getAllFoodTypes, getAllFoods } from '../../Store/Food/slice'
import { getCart } from '../../Store/Cart/slice'
import { useEffect, useState } from 'react'
import MenuFoodTypeList from './MenuFoodTypeList'
import MenuFoodList from './MenuFoodList'
import MenuAddFood from './MenuAddFood'
import MenuPaginate from './MenuPaginate'
import { Link, useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import RouteString from '../../Lib/RouteString'
import React from 'react';

const MenuPage = () => {
    const dispatch = useDispatch()
    const [foodTypeList, setFoodTypeList] = useState([])
    const [foodList, setFoodList] = useState(null)
    const [paginate, setPaginate] = useState([])
    const [cart, setCart] = useState([])
    const { getAllFoodTypesResult, getAllFoodsResult } = useSelector(state => state.food)
    const { getCartResult } = useSelector(state => state.cart)
    const [foodToCart, setFoodToCart] = useState(null)
    const { page } = useParams();
    useEffect(() => {
        dispatch(getAllFoodTypes())
        dispatch(getCart())
        document.title = 'Thực đơn món ăn tại Quán ăn Cường Loan'
        window.scrollTo(0, 0);
    }, [])
    useEffect(() => {
        if (page !== undefined)
            dispatch(getAllFoods(page))
        else
            dispatch(getAllFoods(1))
    }, [page])
    useEffect(() => {
        if (getAllFoodTypesResult !== undefined)
            setFoodTypeList(getAllFoodTypesResult.data)
    }, [getAllFoodTypesResult])
    useEffect(() => {
        if (getAllFoodsResult !== undefined) {
            setPaginate(getAllFoodsResult.meta)
            setFoodList(getAllFoodsResult.data)
        }
    }, [getAllFoodsResult])
    useEffect(() => {
        if (getCartResult !== undefined)
            if (getCartResult.success)
                setCart(JSON.parse(getCartResult.cart))
    }, [getCartResult])
    return (
        <div style={{ opacity: 1 }} className="page-wrapper">
            <Logo />
            <div className="inner-container main center-element">
                <div className="card main mg-bottom-64px">
                    <div className="section hero-default wf-section">
                        <div className="container-default w-container">
                            <div className="inner-container _500px-mbl center-element">
                                <div className="inner-container _710px center-element">
                                    <div className="mg-bottom-56px">
                                        <div className="inner-container _625px center-element">
                                            <div className="mg-bottom-48px">
                                                <div className="inner-container _530px center-element">
                                                    <div className="text-center">
                                                        <h1 className="mg-bottom-16px">Thực đơn tại quán ăn Cường Loan</h1>
                                                        <p>Bên dưới là thực đơn của quán, kính mời Quý Khách tham khảo và đặt món</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-dyn-list">
                                                <MenuFoodTypeList
                                                    props={{
                                                        foodTypeList,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-dyn-list">
                                        {
                                            foodList === null ?
                                                <Skeleton count={5} /> :
                                                <MenuFoodList
                                                    props={{
                                                        foodList,
                                                        setFoodToCart
                                                    }}
                                                />
                                        }

                                    </div>
                                    <h1 />
                                    <MenuPaginate
                                        props={{
                                            paginate,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="loading-bar-wrapper">
                <div style={{ width: '0vw' }} className="loading-bar"></div>
            </div>
            <div className="cart-btn-wrapper" style={{ opacity: 1 }}>
                <div className="w-commerce-commercecartwrapper" >
                    <Link to={RouteString.cartRoute.route}
                        className="w-commerce-commercecartopenlink btn-circle-primary large cart-btn w-inline-block">
                        <div className="w-commerce-commercecartopenlinkcount cart-main-quantity">{cart.length}</div>
                        <div>Giỏ</div>
                    </Link>
                </div>
                <MenuAddFood
                    props={{
                        foodToCart,
                        setFoodToCart,
                        setCart
                    }}
                />
            </div >
            <Footer />
        </div>
    )
}

export default MenuPage