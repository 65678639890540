import RouteString from "../Lib/RouteString"
import { Toaster } from "react-hot-toast"
import HomePage from "./Home"
import RegisterPage from "./Auth/Register/Register"
import LoginPage from "./Auth/Login/Login"
import MenuPage from "./Menu/Menu"
import MenuWithFoodTypePage from "./Menu/MenuWithFoodType"
import FoodPage from "./Food/Food"
import CartPage from "./Cart/Cart"
import CreateOrderPage from "./Order/CreateOrder"
import CreateOrderResultPage from "./Order/CreateOrderResult"
import React from "react"
import OrderListPage from "./Order/OrderList"
import MenuPartyPage from "./MenuParty/MenuParty"
import MenuPartyListPage from "./MenuParty/MenuPartyList"
import MenuPartyDetailPage from "./MenuParty/MenuPartyDetail"
const PageToDisplay = ({ props }) => {
    return (
        <>
            <Toaster />
            {
                (props.Route === RouteString.loginRoute.route) ?
                    < LoginPage />
                    :
                    (() => {
                        switch (props.Route) {
                            case RouteString.registerRoute.route:
                                return <RegisterPage />
                            case RouteString.homeRoute.route:
                                return <HomePage />
                            case RouteString.menuRoute.route:
                            case RouteString.menuPaginateRoute.route:
                                return <MenuPage />
                            case RouteString.menuFoodTypeRoute.route:
                                return <MenuWithFoodTypePage />
                            case RouteString.foodDetailRoute.route:
                                return <FoodPage />
                            case RouteString.cartRoute.route:
                                return <CartPage />
                            case RouteString.createOrderRoute.route:
                                return <CreateOrderPage />
                            case RouteString.orderResultRoute.route:
                                return <CreateOrderResultPage />
                            case RouteString.orderListRoute.route:
                                return <OrderListPage />
                            case RouteString.templateMenuRoute.route:
                                return <MenuPartyPage />
                            case RouteString.templateMenuListRoute.route:
                                return <MenuPartyListPage />
                            case RouteString.templateMenuDetailRoute.route:
                                return <MenuPartyDetailPage />
                            default:
                                return null
                        }
                    })()
            }
        </>
    )
}

export default PageToDisplay