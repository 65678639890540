import React from 'react';

export const DateTimeFromTimestamp = (timstamp) => {
    return `${new Date(timstamp).toLocaleString(
        "vi-VN",
        {
            hour: '2-digit',
            minute: 'numeric',
        }
    )} ngày ${new Date(timstamp).toLocaleDateString(
        "vi-VN",
        {
            month: "short",
            day: "2-digit",
            year: "numeric",
        }
    )}`
}

export const TimstampToYMD = (timstamp) => {
    const date = new Date(timstamp)
    const getYear = date.toLocaleString("default", { year: "numeric" });
    const getMonth = date.toLocaleString("default", { month: "2-digit" });
    const getDay = date.toLocaleString("default", { day: "2-digit" });
    const dateFormat = getYear + "-" + getMonth + "-" + getDay;
    return dateFormat;
}

export const TimestampToDDMMYYYY = (timstamp) => {
    const date = new Date(timstamp)
    const getYear = date.toLocaleString("default", { year: "numeric" });
    const getMonth = date.toLocaleString("default", { month: "2-digit" });
    const getDay = date.toLocaleString("default", { day: "2-digit" });
    const dateFormat = getDay + "-" + getMonth + "-" + getYear;
    return dateFormat;
}

export const CurrentcyConvertVND = (txt) => {
    return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(txt);
}