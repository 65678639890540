import Logo from '../../Layouts/Logo'
import Footer from '../../Layouts/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { getCart, increasingFoodOfCart, decreasingFoodOfCart, deletingFoodFromCart } from '../../Store/Cart/slice'
import { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import CartFoodList from './CartFoodList'
import { useNavigate } from 'react-router-dom'
import RouteString from '../../Lib/RouteString'
import React from 'react';

const CartPage = () => {
    const dispatch = useDispatch()
    const [cart, setCart] = useState(null)
    const { getCartResult, increasingFoodOfCartResult, decreasingFoodOfCartResult, deletingFoodFromCartResult } = useSelector(state => state.cart)
    const customerInfo = JSON.parse(Cookies.get('user'))
    const [propsKey, setPropsKey] = useState(0)
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getCart())
        document.title = 'Giỏ món ăn - Quán ăn Cường Loan'
        window.scrollTo(0, 0);
    }, [])
    useEffect(() => {
        if (getCartResult !== undefined)
            if (getCartResult.success) {
                setCart(JSON.parse(getCartResult.cart))
            }
    }, [getCartResult])
    useEffect(() => {
        if (getCartResult !== undefined)
            if (getCartResult.success) {
                setCart(JSON.parse(getCartResult.cart))
            }
    }, [getCartResult])
    useEffect(() => {
        if (increasingFoodOfCartResult !== undefined)
            if (increasingFoodOfCartResult.success) {
                setPropsKey(propsKey + 1)
                setCart(JSON.parse(increasingFoodOfCartResult.cart))
            }
    }, [increasingFoodOfCartResult])
    useEffect(() => {
        if (decreasingFoodOfCartResult !== undefined)
            if (decreasingFoodOfCartResult.success) {
                setPropsKey(propsKey + 1)
                setCart(JSON.parse(decreasingFoodOfCartResult.cart))
            }
    }, [decreasingFoodOfCartResult])
    useEffect(() => {
        if (deletingFoodFromCartResult !== undefined)
            if (deletingFoodFromCartResult.success) {
                setPropsKey(propsKey + 1)
                setCart(JSON.parse(deletingFoodFromCartResult.cart))
            }
    }, [deletingFoodFromCartResult])
    const increasingFoodOfCartHandle = (foodpriceid) => {
        dispatch(increasingFoodOfCart({ foodpriceid }))
    }
    const decreasingFoodOfCartHandle = (foodpriceid) => {
        dispatch(decreasingFoodOfCart({ foodpriceid }))
    }
    const deletingFoodFromCartHandle = (foodpriceid) => {
        dispatch(deletingFoodFromCart({ foodpriceid }))
    }
    const createOrderHandle = () => {
        navigate(RouteString.createOrderRoute.route)
    }
    return (
        <div style={{ opacity: 1 }} className="page-wrapper">
            <Logo />
            <div className="inner-container main center-element">
                <div className="card main mg-bottom-64px">
                    <div className="section wf-section">
                        <div className="container-default w-container">
                            <div className="inner-container _500px-mbl center-element">
                                <div className="inner-container _710px center-element">
                                    <h2 className="text-center mg-bottom-32px">Giỏ món ăn của {customerInfo.Gender ? 'anh' : 'chị'} {customerInfo.Name}</h2>
                                    {
                                        <CartFoodList
                                            key={propsKey}
                                            props={{
                                                cart,
                                                customerInfo,
                                                increasingFoodOfCartHandle,
                                                decreasingFoodOfCartHandle,
                                                deletingFoodFromCartHandle
                                            }}
                                        />
                                    }

                                    <div className="w-pagination-wrapper pagination">
                                    </div>

                                    {
                                        cart?.length != 0 ?
                                            <button className="btn-primary width-100 w-button"
                                                onClick={() => createOrderHandle()}
                                            >
                                                Đặt các món ăn
                                            </button>
                                            :
                                            ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="loading-bar-wrapper">
                <div style={{ width: '0vw' }} className="loading-bar"></div>
            </div>
            <Footer />
        </div>
    )
}

export default CartPage