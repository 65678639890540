/** @format */
import { APIHost } from "../Lib/Constant";
import axios from "axios";
import Cookies from 'js-cookie';
import React from 'react';

const instance = axios.create({
    baseURL: APIHost,
    headers: {
        // "Content-Type": "application/json",
        // "Accept": "application/json",
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
        // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, Authorization',
    },
})

// Add a request interceptor
instance.interceptors.request.use((req) => {
    if (Cookies.get('token')) {
        req.headers.Authorization = `Bearer ${Cookies.get('token')}`;
    }
    return req;
});

// Add a response interceptor
instance.interceptors.response.use(
    (res) => {
        return res;
    },
    (error) => {
        const status = error.response ? error.response.status : 500;
        if (status && status === 500) {
            localStorage.clear();
        }
        return Promise.reject(error);
    }
);

export default instance;
