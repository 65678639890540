import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useContext } from 'react';
import PageToDisplay from '../Pages';
import RouteString from '../Lib/RouteString';
import { AuthContext } from '../Context/ContextConfig';
export const RouteController = () => {
    const authContext = useContext(AuthContext)
    return (
        <Router>
            <Routes>
                <Route
                    path={RouteString.registerRoute.route}
                    element={
                        <PageToDisplay props={{ Route: RouteString.registerRoute.route }} />
                    }
                />

                <Route
                    path={RouteString.loginRoute.route}
                    element={
                        <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                    }
                />

                <Route
                    path={RouteString.homeRoute.route}
                    element={
                        // authContext.token === null ?
                        //     <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                        //     :
                        <PageToDisplay props={{ Route: RouteString.homeRoute.route }}
                        />
                    }
                />

                <Route
                    path={RouteString.menuRoute.route}
                    element={
                        <PageToDisplay props={{ Route: RouteString.menuRoute.route }} />
                    }
                />

                <Route
                    path={RouteString.menuPaginateRoute.route}
                    element={
                        <PageToDisplay props={{ Route: RouteString.menuPaginateRoute.route }} />
                    }
                />

                <Route
                    path={RouteString.menuFoodTypeRoute.route}
                    element={
                        <PageToDisplay props={{ Route: RouteString.menuFoodTypeRoute.route }} />
                    }
                />

                <Route
                    path={RouteString.foodDetailRoute.route}
                    element={
                        <PageToDisplay props={{ Route: RouteString.foodDetailRoute.route }} />
                    }
                />

                <Route
                    path={RouteString.cartRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.cartRoute.route }}
                            />
                    }
                />

                <Route
                    path={RouteString.createOrderRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.createOrderRoute.route }}
                            />
                    }
                />

                <Route
                    path={RouteString.orderResultRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.orderResultRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.orderListRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.orderListRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.templateMenuRoute.route}
                    element={
                        <PageToDisplay props={{ Route: RouteString.templateMenuRoute.route }} />
                    }
                />
                <Route
                    path={RouteString.templateMenuListRoute.route}
                    element={
                        <PageToDisplay props={{ Route: RouteString.templateMenuListRoute.route }} />
                    }
                />
                <Route
                    path={RouteString.templateMenuDetailRoute.route}
                    element={
                        <PageToDisplay props={{ Route: RouteString.templateMenuDetailRoute.route }} />
                    }
                />
            </Routes>
        </Router>
    )
}