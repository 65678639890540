/** @format */

import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState: {},
    reducers: {
        addFoodToCart: (state, { payload }) => ({
            ...state,
        }),
        addFoodToCartResult: (state, { payload }) => ({
            ...state,
            addFoodToCartResult: payload,
        }),
        getCart: (state, { payload }) => ({
            ...state,
        }),
        getCartResult: (state, { payload }) => ({
            ...state,
            getCartResult: payload,
        }),
        clearAddFoodToCartResult: (state, { payload }) => ({
            ...state,
            addFoodToCartResult: undefined
        }),
        decreasingFoodOfCart: (state, { payload }) => ({
            ...state,
        }),
        decreasingFoodOfCartResult: (state, { payload }) => ({
            ...state,
            decreasingFoodOfCartResult: payload
        }),
        increasingFoodOfCart: (state, { payload }) => ({
            ...state,
        }),
        increasingFoodOfCartResult: (state, { payload }) => ({
            ...state,
            increasingFoodOfCartResult: payload
        }),
        deletingFoodFromCart: (state, { payload }) => ({
            ...state,
        }),
        deletingFoodFromCartResult: (state, { payload }) => ({
            ...state,
            deletingFoodFromCartResult: payload
        }),
    },
});

export const {
    addFoodToCart,
    addFoodToCartResult,
    getCart,
    getCartResult,
    clearAddFoodToCartResult,
    decreasingFoodOfCart,
    decreasingFoodOfCartResult,
    increasingFoodOfCart,
    increasingFoodOfCartResult,
    deletingFoodFromCart,
    deletingFoodFromCartResult
} = cartSlice.actions;
export default cartSlice.reducer;
