import Logo from "../../Layouts/Logo"
import Footer from "../../Layouts/Footer"
import { Link, useNavigate, useParams } from "react-router-dom"
import { getFoodDetail, getAllFoodsByFoodtype } from '../../Store/Food/slice'
import { getCart } from '../../Store/Cart/slice'
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { CurrentcyConvertVND } from '../../Lib/DateConvert'
import MenuAddFood from '../Menu/MenuAddFood'
import React from 'react';
import RouteString from "../../Lib/RouteString"

const FoodPage = () => {
    const { foodSlug } = useParams()
    const [foodDetail, setFoodDetail] = useState(null)
    const [relatedFoodList, setRelatedFoodList] = useState([])
    const [cart, setCart] = useState([])
    const [foodToCart, setFoodToCart] = useState(null)
    const dispatch = useDispatch()
    const { getFoodDetailResult, getAllFoodsByFoodtypeResult } = useSelector(state => state.food)
    const { getCartResult } = useSelector(state => state.cart)
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getCart())
        dispatch(getFoodDetail(foodSlug))
        window.scrollTo(0, 0);
    }, [])
    useEffect(() => {
        if (getFoodDetailResult !== undefined)
            if (!getFoodDetailResult?.success) {
                setFoodDetail(getFoodDetailResult.data)
                dispatch(getAllFoodsByFoodtype(getFoodDetailResult.data.Type[0].type.FOODTYPESLUG))
                document.title = `Món ${getFoodDetailResult.data.Name} - Quán ăn Cường Loan`
            } else {

            }
    }, [getFoodDetailResult])
    useEffect(() => {
        if (getCartResult !== undefined)
            if (getCartResult.success)
                setCart(JSON.parse(getCartResult.cart))
    }, [getCartResult])
    useEffect(() => {
        if (getAllFoodsByFoodtypeResult !== undefined)
            setRelatedFoodList(getAllFoodsByFoodtypeResult.data)
    }, [getAllFoodsByFoodtypeResult])
    return (
        <div style={{ opacity: 1 }} className="page-wrapper">
            <Logo />
            {
                (foodDetail !== null) ?
                    <>
                        <div className="inner-container main center-element">
                            <div className="card main mg-bottom-64px">
                                <div className="image-wrapper position-relative">
                                    <img
                                        crossOrigin="anonymouse"
                                        src={foodDetail.Image[0].image.IMAGEURL}
                                        loading="eager" alt={foodDetail.Name}
                                        sizes="(max-width: 767px) 100vw, (max-width: 991px) 93vw, 838px"
                                        className="image cover" />
                                    <div className="position-absolute bottom">
                                        <div className="container-default w-container">
                                            <div className="mg-bottom-64px">
                                                <div className="inner-container _500px-mbl center-element">
                                                    {
                                                        foodDetail.Variant.map((e) => (
                                                            (e.ISSHOW) ?
                                                                <div className="inner-container _710px center-element">
                                                                    <div className="badge-secondary large">
                                                                        <div className="text-300 extra-bold color-neutral-800">{e.VARIANT} - {CurrentcyConvertVND(e.PRICEVALUE)}</div>
                                                                    </div>
                                                                </div>
                                                                : ''
                                                        ))
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section pd-top-64px wf-section">
                                    <div className="container-default w-container">
                                        <div className="inner-container _500px-mbl center-element">
                                            <div className="inner-container _710px center-element">
                                                <div className="flex-horizontal space-between align-center flex-vertical-mbl">
                                                    <div className="mg-bottom-35px-mbl">
                                                        <h1 className="heading-h2-size">{foodDetail.Name}</h1>
                                                        <div className="hidden-on-desktop display-block-mbl">
                                                            <div className="inner-container _358px">
                                                                <p className="mg-bottom-28px">Món ăn được phục vụ tại Quán ăn Cường Loan</p>
                                                            </div>
                                                        </div>
                                                        <div className="w-layout-grid grid-4-columns menu-options">
                                                            <div>
                                                                <div className="flex-horizontal align-center">
                                                                    <div className="mg-right-6px">
                                                                        <i className="fa-solid fa-truck"></i>
                                                                    </div>
                                                                    <div className="text-100 semi-bold color-neutral-800 text-size-16px">
                                                                        Phục vụ tận nhà
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="flex-horizontal align-center">
                                                                    <div className="mg-right-6px">
                                                                        <i className="fa-solid fa-leaf"></i>
                                                                    </div>
                                                                    <div className="text-100 semi-bold color-neutral-800 text-size-16px">
                                                                        Rau ăn kèm
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="flex-horizontal align-center">
                                                                    <div className="mg-right-6px">
                                                                        <i className="fa-solid fa-pepper-hot"></i>
                                                                    </div>
                                                                    <div className="text-100 semi-bold color-neutral-800 text-size-16px">
                                                                        Tùy chỉnh độ cay
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="flex-horizontal align-center">
                                                                    <div className="mg-right-6px">
                                                                        <i className="fa-solid fa-utensils"></i>
                                                                    </div>
                                                                    <div className="text-100 semi-bold color-neutral-800 text-size-16px">
                                                                        Dụng cụ ăn uống
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            (foodDetail.isShow) ?
                                                                <button className="btn-primary w-button"
                                                                    onClick={() => setFoodToCart(foodDetail)}
                                                                >
                                                                    <i className="fa-solid fa-cart-plus"></i>
                                                                    <p>Thêm vào giỏ món ăn</p>
                                                                </button>
                                                                : ''
                                                        }

                                                    </div>
                                                </div>
                                                <div className="divider _54px"></div>
                                                <div className="mg-bottom-24px">
                                                    <h2 className="heading-h3-size mg-bottom-16px">Thông tin món ăn</h2>
                                                    <div className="rich-text w-richtext">
                                                        <p>{foodDetail.Description}</p>
                                                    </div>
                                                    <div className="rich-text w-richtext">
                                                        <p>Loại món ăn: {foodDetail.Type[0].type.FOODTYPENAME}</p>
                                                    </div>
                                                </div>
                                                <div className="mg-bottom-24px">
                                                    <h2 className="heading-h3-size mg-bottom-16px">Hình ảnh món ăn thực tế</h2>
                                                    <div className="inner-container _500px-mbl center-element">
                                                        <div className="inner-container _710px center-element">
                                                            <div className="w-layout-grid grid-2-columns gap-0 grip-row-60px-mbl">
                                                                {
                                                                    foodDetail.Image.map((e) => (
                                                                        <div className="w-layout-grid grid-1-column gap-row-123px grip-row-40px-mbl">
                                                                            <div className="inner-container _318px max-w-100-mbl">
                                                                                <div className="image-wrapper border-radius-14px">
                                                                                    <img
                                                                                        crossOrigin="anonymouse"
                                                                                        src={e.image.IMAGEURL}
                                                                                        loading="eager" sizes="(max-width: 479px) 92vw, (max-width: 767px) 90vw, 318px"
                                                                                        alt={foodDetail.Name}
                                                                                        className="image cover" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h2 className="heading-h3-size mg-bottom-16px">Cam kết</h2>
                                                    <div className="rich-text w-richtext">
                                                        <p>Món ăn được chế biến từ thực phẩm tươi sống, hoàn toàn không chứa bất kỳ hóa chất bảo quản nào.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hidden-on-desktop display-block-mbl">
                            <div className="container-default w-container">
                                <div className="divider"></div>
                            </div>
                        </div>
                        <div
                            className="inner-container main center-element">
                            <div className="card main">
                                <div className="section wf-section">
                                    <div className="container-default w-container">
                                        <div className="inner-container _500px-mbl center-element">
                                            <div className="inner-container _710px center-element">
                                                <div className="mg-bottom-32px">
                                                    <div className="flex-horizontal space-between align-center">
                                                        <div>
                                                            <h2 className="mg-bottom-0">Các món ăn khác</h2>
                                                        </div>
                                                        <div className="hidden-mbp">
                                                            <Link to="/menu" className="btn-secondary w-button">Xem thực đơn</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-dyn-list">
                                                    <div role="list" className="grid-1-column gap-row-24px gap-row-80px-mbl w-dyn-items">
                                                        {
                                                            relatedFoodList.map((e) => (
                                                                e.Slug !== foodSlug ?
                                                                    <div role="listitem" className="w-dyn-item">
                                                                        <Link to={`/food/${e.Slug}`} target="_blank"
                                                                            className="link-content menu w-inline-block">
                                                                            <div className="flex-horizontal align-center flex-vertical-mbl">
                                                                                <div className="mg-right-35px mg-right-0px-mbl mg-bottom-44px-mbl">
                                                                                    <div className="inner-container _240px max-w-100-mbl">
                                                                                        <div className="image-wrapper border-radius-16px">
                                                                                            <img
                                                                                                crossOrigin="anonymouse"
                                                                                                src={e.Image[0].image.IMAGEURL}
                                                                                                loading="eager" alt="Classic Burger"
                                                                                                className="image cover" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="inner-container _430px max-w-100-mbl">
                                                                                    <div className="mg-bottom-11px">
                                                                                        <div
                                                                                            className="flex-horizontal space-between align-center children-wrap">
                                                                                            <div className="mg-right-16px">
                                                                                                <h2 className="heading-h3-size mg-bottom-0">
                                                                                                    {e.Name}
                                                                                                </h2>
                                                                                            </div>
                                                                                            <div className="link-text heading-h5-size">
                                                                                                Xem chi tiết
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <p className="mg-bottom-32px mg-bottom-18px-mbl">
                                                                                        {e.Description}
                                                                                    </p>
                                                                                    <div className="w-layout-grid grid-4-columns menu-options">
                                                                                        <div>
                                                                                            <div className="flex-horizontal align-center">
                                                                                                <div className="mg-right-6px">
                                                                                                    <i className="fa-solid fa-truck"></i>
                                                                                                </div>
                                                                                                <div className="text-100 semi-bold color-neutral-800 text-size-16px">
                                                                                                    Phục vụ tận nhà
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                    : ''
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <Skeleton height={50} count={5} />
            }
            <div className="cart-btn-wrapper" style={{ opacity: 1 }}>
                <div className="w-commerce-commercecartwrapper" >
                    <Link to={RouteString.cartRoute.route}
                        className="w-commerce-commercecartopenlink btn-circle-primary large cart-btn w-inline-block">
                        <div className="w-commerce-commercecartopenlinkcount cart-main-quantity">{cart.length}</div>
                        <div>Giỏ</div>
                    </Link>
                </div>
                <MenuAddFood
                    props={{
                        foodToCart,
                        setFoodToCart,
                        setCart
                    }}
                />
            </div >
            <Footer />
            <div className="loading-bar-wrapper">
                <div style={{ width: '0vw' }} className="loading-bar"></div>
            </div>
        </div>
    )
}

export default FoodPage