/** @format */

import { takeLatest } from "redux-saga/effects";
import {
  handleLoginAPI,
  handleRegisterAPI
} from "./handlers";
import {
  login,
  register,
} from "./slice";

export default function* authsWatcher() {
  yield takeLatest(login.type, handleLoginAPI);
  yield takeLatest(register.type, handleRegisterAPI);
}
