/** @format */

import {
    getAllFoodTypesAPI,
    getAllFoodsAPI,
    getAllFoodsByFoodtypeAPI,
    getFoodDetailAPI,
    getMenusAPI,
    getMenuDetailAPI
} from '../../API/Food';
import { call, put } from "redux-saga/effects";
import {
    getAllFoodTypesResult,
    getAllFoodsResult,
    getAllFoodsByFoodtypeResult,
    getFoodDetailResult,
    getMenusResult,
    getMenuDetailResult
} from "./slice";

function* handleGetFoodDetailAPI({ payload }) {
    try {
        const response = yield call(getFoodDetailAPI, payload);
        if (response.status === 200) {
            yield put(getFoodDetailResult(response.data));
        }
    } catch (error) {
        yield put(getFoodDetailResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetAllFoodsByFoodtypeAPI({ payload }) {
    try {
        const response = yield call(getAllFoodsByFoodtypeAPI, payload);
        if (response.status === 200) {
            yield put(getAllFoodsByFoodtypeResult(response.data));
        }
    } catch (error) {
        yield put(getAllFoodsByFoodtypeResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetAllFoodTypesAPI({ payload }) {
    try {
        const response = yield call(getAllFoodTypesAPI, payload);
        if (response.status === 200) {
            yield put(getAllFoodTypesResult(response.data));
        }
    } catch (error) {
        yield put(getAllFoodTypesResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetAllFoodsAPI({ payload }) {
    try {
        const response = yield call(getAllFoodsAPI, payload);
        if (response.status === 200) {
            yield put(getAllFoodsResult(response.data));
        }
    } catch (error) {
        yield put(getAllFoodsResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetMenusAPI({ payload }) {
    try {
        const response = yield call(getMenusAPI, payload);
        if (response.status === 200) {
            yield put(getMenusResult(response.data));
        }
    } catch (error) {
        yield put(getMenusResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetMenuDetailAPI({ payload }) {
    try {
        const response = yield call(getMenuDetailAPI, payload);
        if (response.status === 200) {
            yield put(getMenuDetailResult(response.data));
        }
    } catch (error) {
        yield put(getMenuDetailResult({ success: false, code: Math.random(1000) }));
    }
}
export {
    handleGetAllFoodTypesAPI,
    handleGetAllFoodsAPI,
    handleGetAllFoodsByFoodtypeAPI,
    handleGetFoodDetailAPI,
    handleGetMenusAPI,
    handleGetMenuDetailAPI
};
