import Logo from '../Layouts/Logo'
import Header from '../Layouts/Header'
import Footer from '../Layouts/Footer'
import { useEffect } from 'react'
import React from 'react'
const HomePage = () => {
    useEffect(()=>{
        document.title = 'Quán ăn Cường Loan Sóc Trăng - Bánh tráng phơi sương'
        window.scrollTo(0, 0);
    },[])
    return (
        <div style={{ opacity: 1 }} className="page-wrapper">
            <Logo />
            <div
                className="inner-container main center-element">
                <div className="card main">
                    <Header />
                </div>
            </div>
            <div className="loading-bar-wrapper">
                <div style={{ width: '0vw' }} className="loading-bar"></div>
            </div>
            <Footer />
        </div>
    )
}

export default HomePage