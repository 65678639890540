import Logo from '../../Layouts/Logo'
import Footer from '../../Layouts/Footer'
import { useEffect } from 'react'
import React from 'react';
import { Link } from 'react-router-dom';
const MenuPartyPage = () => {
    useEffect(() => {
        document.title = 'Mẫu menu đặt tiệc - Quán ăn Cường Loan'
        window.scrollTo(0, 0);
    }, [])
    return (
        <div style={{ opacity: 1 }} className="page-wrapper">
            <Logo />
            <div className="inner-container main center-element">
                <div className="card main">
                    <div className="section bg-accent-1">
                        <div className="container-default w-container">
                            <div className="inner-container _710px center-element">
                                <div className="text-center">
                                    <h1 className="color-neutral-100 mg-bottom-8px">Menu mẫu đặt tiệc</h1>
                                    <p className="color-neutral-200">Mời Quý khách tham khảo một số mẫu menu quán thường phục vụ tiệc
                                    </p>
                                </div>
                                <div className="buttons-row center mg-top-40px">
                                    <div className="button-row-first">
                                        <Link to={`/menu-type/1`} className="btn-primary white w-button">Menu tiệc tận nhà</Link>
                                    </div>
                                    <div className="button-row-last">
                                        <Link to={`/menu-type/2`} className="btn-secondary white w-button">Menu tiệc cưới</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="loading-bar-wrapper">
                <div style={{ width: '0vw' }} className="loading-bar"></div>
            </div>
            <Footer />
        </div >
    )
}

export default MenuPartyPage