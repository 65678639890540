/** @format */

import { takeLatest } from "redux-saga/effects";
import {
  handleGetAllFoodTypesAPI,
  handleGetAllFoodsAPI,
  handleGetAllFoodsByFoodtypeAPI,
  handleGetFoodDetailAPI,
  handleGetMenusAPI,
  handleGetMenuDetailAPI
} from "./handlers";
import {
  getAllFoodTypes,
  getAllFoods,
  getAllFoodsByFoodtype,
  getFoodDetail,
  getMenus,
  getMenuDetail
} from "./slice";

export default function* foodsWatcher() {
  yield takeLatest(getAllFoodTypes.type, handleGetAllFoodTypesAPI);
  yield takeLatest(getAllFoods.type, handleGetAllFoodsAPI);
  yield takeLatest(getAllFoodsByFoodtype.type, handleGetAllFoodsByFoodtypeAPI);
  yield takeLatest(getFoodDetail.type, handleGetFoodDetailAPI);
  yield takeLatest(getMenus.type, handleGetMenusAPI);
  yield takeLatest(getMenuDetail.type, handleGetMenuDetailAPI);
}
