/** @format */

import instance from '.';
import React from 'react';

export const createOrderAPI = async (payload) => {
    return await instance.request({
        method: "POST",
        url: `/create-order`,
        data: payload
    });
};

export const getOrderDetailAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/get-order/${payload}`,
    });
};

export const getOrdersAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/get-orders`,
    });
};
