/** @format */

import {
    createOrderAPI,
    getOrderDetailAPI,
    getOrdersAPI
} from '../../API/Order';
import { call, put } from "redux-saga/effects";
import {
    createOrderResult,
    getOrderDetailResult,
    getOrdersResult
} from "./slice";

function* handleGetOrderDetailAPI({ payload }) {
    try {
        const response = yield call(getOrderDetailAPI, payload);
        if (response.status === 200) {
            yield put(getOrderDetailResult(response.data));
        }
    } catch (error) {
        yield put(getOrderDetailResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleCreateOrderAPI({ payload }) {
    try {
        const response = yield call(createOrderAPI, payload);
        if (response.status === 201) {
            yield put(createOrderResult(response.data));
        }
    } catch (error) {
        yield put(createOrderResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetOrdersAPI({ payload }) {
    try {
        const response = yield call(getOrdersAPI, payload);
        if (response.status === 200) {
            yield put(getOrdersResult(response.data));
        }
    } catch (error) {
        yield put(getOrdersResult({ success: false, code: Math.random(1000) }));
    }
}

export {
    handleCreateOrderAPI,
    handleGetOrderDetailAPI,
    handleGetOrdersAPI
};
