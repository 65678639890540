import { Link } from 'react-router-dom'
import HeaderLogo from '../Assets/image/HeaderLogo.svg'
import Cookies from 'js-cookie';
import React from 'react';
const Logo = () => {
    // const customerInfo = JSON.parse(Cookies.get('user'))
    return (
        // <div className="header-wrapper w-nav">
        //     <div className="container-default w-container">
        //         <div className="header-content-wrapper">
        //             <div className="header-middle">
        //                 <Link to="/" aria-current="page"
        //                     className="header-logo-link w-nav-brand w--current"
        //                 ><img
        //                         src={HeaderLogo}
        //                         loading="eager" alt="Burgers Logo - QRCode X Webflow template"
        //                         className="header-logo" />
        //                 </Link>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className="header w-nav" style={{ background: '#fff' }}>
            <div className="container-default w-container">
                {/* <div className="header-wrapper">
                    <div className="split-content header-right">
                        <a href="/home" className="brand w-nav-brand">
                            <img
                                src={HeaderLogo}
                                loading="eager" alt="Burgers Logo - QRCode X Webflow template"
                                className="header-logo" />
                        </a>
                    </div>
                    <div className="split-content header-left">
                        <div className="header-buttons-wrapper"><a href="/menu"
                            className="button-secondary small mg-right-24px w-button">Order online</a><a href="/contact"
                                className="button-primary small w-button">Reservate</a></div>
                    </div>
                </div> */}
                <div className="header-content-wrapper">
                    <div className="header-middle">
                        <div className="split-content header-right">
                            <Link to="/" aria-current="page"
                                className="header-logo-link w-nav-brand w--current"
                            >
                                <img
                                    src={HeaderLogo}
                                    loading="eager" alt="Quán ăn Cường Loan Sóc Trăng - Bánh tráng phơi sương"
                                    className="header-logo" />
                            </Link>
                        </div>
                        {/* <div className="split-content header-left">
                            <div className="header-buttons-wrapper ">

                                <button
                                    className="btn-primary small w-button">
                                    <i className="fa-solid fa-circle-user"></i>
                                </button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
        </div >
    )
}

export default Logo