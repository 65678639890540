import Logo from '../../../Layouts/Logo'
import Footer from '../../../Layouts/Footer'
import { useEffect, useState } from 'react'
import { vietnamePhone, vietnameseName } from '../../../Lib/Regex'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { register } from '../../../Store/Auth/slice'
import RouteString from '../../../Lib/RouteString'
import { Link } from 'react-router-dom'
import React from 'react';

const RegisterPage = () => {
    const [customerphone, setCustomerphone] = useState('')
    const [customername, setCustomername] = useState('')
    const [customeraddress, setCustomeraddress] = useState('')
    const [customergender, setCustomergender] = useState(1)
    const [customerpassword, setCustomerpassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch()
    const { registerResult } = useSelector(state => state.auth)
    const registerHandle = () => {
        toast.remove()
        let check = 0
        if (customerphone.match(vietnamePhone) === null) {
            check = 1
            toast.error('Quý Khách vui lòng kiểm tra lại số điện thoại của mình')
        }
        if (!vietnameseName.test(customername)) {
            check = 1
            toast.error('Quý Khách vui lòng kiểm tra lại tên của mình')
        }
        if (customeraddress === '') {
            check = 1
            toast.error('Quý Khách vui lòng nhập địa chỉ')
        }
        if (customerpassword === '') {
            check = 1
            toast.error('Quý Khách vui lòng nhập mật khẩu')
        }
        if (check === 0) {
            dispatch(register({
                customerphone,
                customeraddress,
                customergender,
                customername,
                customerpassword
            }))
        }
    }
    useEffect(() => {
        document.title = 'Đăng ký - Quán ăn Cường Loan'
        window.scrollTo(0, 0);
    }, [])
    useEffect(() => {
        if (registerResult !== undefined) {
            toast.remove()
            if (registerResult.success) {
                toast.success(registerResult.message);
                setCustomerphone('')
                setCustomeraddress('')
                setCustomername('')
                setCustomerpassword('')
            } else {
                const errorMessages = Object.values(registerResult.message).flat();
                setErrorMessage(errorMessages.join(', '));
                toast.error(errorMessages.join(', '));
            }
        }

    }, [registerResult])
    return (
        <div style={{ opacity: 1 }} className="page-wrapper">
            <Logo />
            <div className="utility-page-wrap password">
                <div className="inner-container main center-element mg-bottom-0 w-password-page w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" action="/.wf_auth" method="post"
                        className="card password w-password-page">
                        <div className="section pd-0px wf-section">
                            <div className="inner-container _500px-mbl center-element">
                                <div className="inner-container _550px center-element">
                                    <div className="text-center">
                                        <h1 className="heading-h2-size mg-bottom-16px">Kính mời Quý Khách đăng ký tài khoản để thực hiện đặt món ăn trên website</h1>
                                        <p className="mg-bottom-24px">Quý Khách nhập các thông tin theo mẫu bên dưới để tiến hành tạo tài khoản</p>
                                        <div className="w-layout-grid grid-1-column">
                                            <div className="w-form"><label htmlFor="customerphone">Số điện thoại của Quý Khách</label>
                                                <input type="text"
                                                    className="input w-input" maxLength="256"
                                                    placeholder="Quý Khách nhập số điện thoại ở đây"
                                                    value={customerphone}
                                                    onChange={(e) => setCustomerphone(e.target.value)}
                                                    id="customerphone"
                                                />
                                            </div>
                                            <div className="w-form"><label htmlFor="customername">Quán xin được biết tên của Quý Khách</label>
                                                <input type="text"
                                                    className="input w-input" maxLength="256"
                                                    placeholder="Quý Khách nhập tên của mình ở đây"
                                                    value={customername}
                                                    onChange={(e) => setCustomername(e.target.value)}
                                                    id="customername"
                                                />
                                            </div>
                                            <div className="w-form"><label>Quý Khách muốn quán gọi là</label>
                                                <label className="radio-button-field-wrapper w-radio" onClick={() => setCustomergender(1)}>
                                                    <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input ${!customergender ? '' : 'w--redirected-checked'}`}></div>
                                                    <span className="w-form-label">Anh</span>
                                                </label>
                                                <label className="radio-button-field-wrapper w-radio" onClick={() => setCustomergender(0)}>
                                                    <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input ${customergender ? '' : 'w--redirected-checked'}`}></div>
                                                    <span className="w-form-label">Chị</span>
                                                </label>
                                            </div>
                                            <div className="w-form"><label htmlFor="customeraddress">Địa chỉ của Quý Khách để nhận thức ăn</label>
                                                <input type="text"
                                                    className="input w-input" maxLength="256"
                                                    placeholder="Quý Khách nhập địa chỉ của mình ở đây"
                                                    value={customeraddress}
                                                    onChange={(e) => setCustomeraddress(e.target.value)}
                                                    id="customeraddress"
                                                />
                                            </div>
                                            <div className="w-form"><label htmlFor="customerpassword">Mật khẩu của Quý Khách</label>
                                                <input type="password"
                                                    className="input w-input" maxLength="256"
                                                    placeholder="Quý Khách nhập mật khẩu của mình ở đây"
                                                    value={customerpassword}
                                                    onChange={(e) => setCustomerpassword(e.target.value)}
                                                    id="customerpassword"
                                                />
                                            </div>
                                            <button
                                                type="button"
                                                className="btn-primary w-button"
                                                onClick={() => registerHandle()}
                                            >
                                                Đăng ký
                                            </button>
                                        </div>
                                        <p className="mg-bottom-24px">Quý Khách đã có tài khoản? Hãy bấm <Link to={RouteString.loginRoute.route}>vào đây để đăng nhập</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="loading-bar-wrapper">
                <div style={{ width: '0vw' }} className="loading-bar"></div>
            </div>
            <Footer />
        </div>
    )
}

export default RegisterPage