/** @format */

import {
    loginAPI,
    registerAPI
} from '../../API/Auth';
import { call, put } from "redux-saga/effects";
import {
    registerResult,
    loginResult,
} from "./slice";

function* handleRegisterAPI({ payload }) {
    try {
        const response = yield call(registerAPI, payload);
        if (response.status === 201) {
            yield put(registerResult(response.data));
        }
    } catch (error) {
        yield put(registerResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleLoginAPI({ payload }) {
    try {
        const response = yield call(loginAPI, payload);
        if (response.status === 201) {
            yield put(loginResult(response.data));
        }
    } catch (error) {
        yield put(loginResult({ success: false, code: Math.random(1000) }));
    }
}

export {
    handleLoginAPI,
    handleRegisterAPI
};
