/** @format */
import React from 'react';

import instance from '.';

export const loginAPI = async (payload) => {
    return await instance.request({
        method: "POST",
        url: `/login`,
        data: payload
    });
};

export const registerAPI = async (payload) => {
    return await instance.request({
        method: "POST",
        url: `/register`,
        data: payload
    });
};
