/** @format */

import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
    name: "order",
    initialState: {},
    reducers: {
        createOrder: (state, { payload }) => ({
            ...state,
        }),
        createOrderResult: (state, { payload }) => ({
            ...state,
            createOrderResult: payload,
        }),
        getOrderDetail: (state, { payload }) => ({
            ...state,
        }),
        getOrderDetailResult: (state, { payload }) => ({
            ...state,
            getOrderDetailResult: payload,
        }),
        getOrders: (state, { payload }) => ({
            ...state,
        }),
        getOrdersResult: (state, { payload }) => ({
            ...state,
            getOrdersResult: payload,
        }),
    },
});

export const {
    createOrder,
    createOrderResult,
    getOrderDetail,
    getOrderDetailResult,
    getOrders,
    getOrdersResult
} = orderSlice.actions;
export default orderSlice.reducer;
