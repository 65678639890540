import Logo from '../../../Layouts/Logo'
import Footer from '../../../Layouts/Footer'
import { useContext, useEffect, useState } from 'react'
import { vietnamePhone } from '../../../Lib/Regex'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../../Store/Auth/slice'
import { AuthContext } from '../../../Context/ContextConfig'
import Cookies from 'js-cookie';
import RouteString from '../../../Lib/RouteString'
import { Link } from 'react-router-dom'
import React from 'react';

const LoginPage = () => {
    const [customerphone, setCustomerphone] = useState('')
    const [customerpassword, setCustomerpassword] = useState('')
    const dispatch = useDispatch()
    const auhtContext = useContext(AuthContext)
    const { loginResult } = useSelector(state => state.auth)
    const loginHandle = () => {
        toast.remove()
        let check = 0
        if (customerphone.match(vietnamePhone) === null) {
            check = 1
            toast.error('Quý Khách vui lòng kiểm tra lại số điện thoại của mình')
        }
        if (customerpassword === '') {
            check = 1
            toast.error('Quý Khách vui lòng nhập mật khẩu')
        }
        if (check === 0) {
            dispatch(login({
                phone: customerphone,
                password: customerpassword
            }))
        }
    }
    useEffect(() => {
        document.title = 'Đăng nhập - Quán ăn Cường Loan'
        window.scrollTo(0, 0);
    }, [])
    useEffect(() => {
        if (loginResult !== undefined) {
            toast.remove()
            if (loginResult.success) {
                toast.success('Quý Khách đã đăng nhập thành công');
                setCustomerphone('')
                setCustomerpassword('')
                auhtContext.setLogin(loginResult.token, JSON.stringify(loginResult.user))
                Cookies.set('token', loginResult.token, { expires: 7 })
                Cookies.set('user', JSON.stringify(loginResult.user), { expires: 7 })
            } else
                toast.error(loginResult.message);
        }
    }, [loginResult])
    return (
        <div style={{ opacity: 1 }} className="page-wrapper">
            <Logo />
            <div className="utility-page-wrap password">
                <div className="inner-container main center-element mg-bottom-0 w-password-page w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" action="/.wf_auth" method="post"
                        className="card password w-password-page">
                        <div className="section pd-0px wf-section">
                            <div className="inner-container _500px-mbl center-element">
                                <div className="inner-container _550px center-element">
                                    <div className="text-center">
                                        <h1 className="heading-h2-size mg-bottom-16px">Quý Khách sử dụng tài khoản đã đăng ký để tiến hành đăng nhập</h1>
                                        <p className="mg-bottom-24px">Quý Khách nhập các thông tin theo mẫu bên dưới để tiến hành đăng nhập</p>
                                        <div className="w-layout-grid grid-1-column">
                                            <div className="w-form"><label htmlFor="customerphone">Số điện thoại của Quý Khách</label>
                                                <input type="text"
                                                    className="input w-input" maxLength="256"
                                                    placeholder="Quý Khách nhập số điện thoại ở đây"
                                                    value={customerphone}
                                                    onChange={(e) => setCustomerphone(e.target.value)}
                                                    onKeyDownCapture={(e) => (e.code === 'Enter' ? loginHandle() : {})}
                                                    id="customerphone"
                                                />
                                            </div>
                                            <div className="w-form"><label htmlFor="customerpassword">Mật khẩu của Quý Khách</label>
                                                <input type="password"
                                                    className="input w-input" maxLength="256"
                                                    placeholder="Quý Khách nhập mật khẩu của mình ở đây"
                                                    value={customerpassword}
                                                    onChange={(e) => setCustomerpassword(e.target.value)}
                                                    onKeyDownCapture={(e) => (e.code === 'Enter' ? loginHandle() : {})}
                                                    id="customerpassword"
                                                />
                                            </div>
                                            <button
                                                type="button"
                                                className="btn-primary w-button"
                                                onClick={() => loginHandle()}
                                            >
                                                Đăng nhập
                                            </button>
                                        </div>
                                        <p className="mg-bottom-24px">Quý Khách chưa có tài khoản? Hãy bấm <Link to={RouteString.registerRoute.route}>vào đây để đăng ký</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="loading-bar-wrapper">
                <div style={{ width: '0vw' }} className="loading-bar"></div>
            </div>
            <Footer />
        </div>
    )
}

export default LoginPage