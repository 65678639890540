/** @format */

import { takeLatest } from "redux-saga/effects";
import {
  handleAddFoodToCartAPIAPI,
  handleGetCartAPI,
  handleDecreasingFoodOfCartAPI,
  handleIncreasingFoodOfCartAPI,
  handleDeletingFoodFromCartAPI
} from "./handlers";
import {
  addFoodToCart,
  getCart,
  decreasingFoodOfCart,
  increasingFoodOfCart,
  deletingFoodFromCart
} from "./slice";

export default function* foodsWatcher() {
  yield takeLatest(addFoodToCart.type, handleAddFoodToCartAPIAPI);
  yield takeLatest(getCart.type, handleGetCartAPI);
  yield takeLatest(increasingFoodOfCart.type, handleIncreasingFoodOfCartAPI);
  yield takeLatest(decreasingFoodOfCart.type, handleDecreasingFoodOfCartAPI);
  yield takeLatest(deletingFoodFromCart.type, handleDeletingFoodFromCartAPI);
}
