import { Link } from "react-router-dom"
import RouteString from '../.././Lib/RouteString'
import React from 'react';

const MenuFoodTypeList = ({ props }) => {
    return (
        <div role="list" className="grid-4-columns gap-18px categories w-dyn-items">
            <div role="listitem" className="flex-horizontal flex-vertical-mbl w-dyn-item">
                <Link
                    to={RouteString.menuRoute.route} aria-current="page"
                    className={`badge-secondary category all w-button ${(props.foodTypeParam === undefined) ? ' w--current' : ''}`}>
                    Tất cả
                </Link>
            </div>
            {
                props.foodTypeList.map((e) => (
                    <div role="listitem" className="flex-horizontal flex-vertical-mbl w-dyn-item" key={e.Slug}>
                        <Link
                            to={`${RouteString.menuRoute.route}/${e.Slug}`} className={`badge-secondary category w-button ${(props.foodTypeParam === e.Slug) ? ' w--current' : ''}`}>
                            {e.Name}
                        </Link>
                    </div>
                ))
            }
        </div>
    )
}

export default MenuFoodTypeList