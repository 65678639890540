import { useEffect, useState } from "react"
import React from "react"
import { CurrentcyConvertVND } from "../../Lib/DateConvert"
import { useDispatch, useSelector } from "react-redux"
import { addFoodToCart, clearAddFoodToCartResult } from "../../Store/Cart/slice"
import { toast } from "react-hot-toast"
import { AuthContext } from "../../Context/ContextConfig"
import { useContext } from "react"
import RouteString from "../../Lib/RouteString"
const MenuAddFood = ({ props }) => {
    const [variantSelected, setVariantSelected] = useState(0)
    const [count, setCount] = useState(1)
    const dispatch = useDispatch()
    const { addFoodToCartResult } = useSelector(state => state.cart)
    const authContext = useContext(AuthContext)
    const addFoodToCartHandle = () => {
        dispatch(addFoodToCart({
            foodpriceid: props.foodToCart?.Variant[variantSelected].PRICEID,
            count
        }))
    }
    useEffect(() => {
        if (addFoodToCartResult !== undefined) {
            let addFoodToCartResultTemp = addFoodToCartResult
            if (addFoodToCartResultTemp.success) {
                toast.success(addFoodToCartResultTemp.message)
                props.setFoodToCart(null)
                props.setCart(JSON.parse(addFoodToCartResultTemp.cart))
            }
        }
        dispatch(clearAddFoodToCartResult())
    }, [addFoodToCartResult])
    return (
        (props.foodToCart !== null)
            ?
            <div>
                <div style={{ opacity: (props.foodToCart === null ? 0 : 1), transition: 'opacity 300ms ease 0s', display: (props.foodToCart === null ? 'none' : '') }}
                    className="w-commerce-commercecartcontainerwrapper w-commerce-commercecartcontainerwrapper--cartType-leftSidebar cart-wrapper">
                    <div className="w-commerce-commercecartcontainer cart-main-container">
                        <div className="w-commerce-commercecartheader cart-header">
                            <h4 className="w-commerce-commercecartheading">Thêm món ăn vào đơn</h4>
                            <button className="w-commerce-commercecartcloselink cart-close-button w-inline-block"
                                onClick={() => props.setFoodToCart(null)}
                            >
                                X
                            </button>
                        </div>
                        <div className="w-commerce-commercecartformwrapper cart-form-wrapper">
                            {
                                (authContext.token !== null) ?
                                    <>
                                        <div className="w-commerce-commercecartlist cart-list">
                                            <div className="w-commerce-commercecartitem order-item">
                                                <a href="#" className="image-wrapper border-radius-20px order-item-image w-inline-block">
                                                    <img
                                                        crossOrigin="anonymous"
                                                        src={props.foodToCart.Image[0].image.IMAGEURL}
                                                        alt={props.foodToCart.Name} className="w-commerce-commercecartitemimage image cover" /></a>
                                                <div className="w-commerce-commercecartiteminfo order-item-content">
                                                    <p className="order-item-title">{props.foodToCart.Name}</p>
                                                    <div className="order-item-price">
                                                        {
                                                            props.foodToCart.Variant[variantSelected] && (
                                                                <span className="order-item-semi-bold">
                                                                    {CurrentcyConvertVND(props.foodToCart.Variant[variantSelected].PRICEVALUE)}
                                                                </span>
                                                            )
                                                        }
                                                    </div>
                                                    <ul className="w-commerce-commercecartoptionlist">
                                                        <li className="cart-item-option"><span>Mô tả: </span>
                                                            {
                                                                props.foodToCart.Variant[variantSelected] && (
                                                                    <span className="order-item-semi-bold">
                                                                        {props.foodToCart.Variant[variantSelected].VARIANT}
                                                                    </span>
                                                                )
                                                            }
                                                        </li>
                                                        <li className="cart-item-option"><span>Khẩu phần: </span>
                                                            {
                                                                props.foodToCart.Variant[variantSelected] && (
                                                                    <span className="order-item-semi-bold">
                                                                        {props.foodToCart.Variant[variantSelected].RATION} người
                                                                    </span>
                                                                )
                                                            }
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="inner-container _365px width-100 max-w-100-mbp">
                                                <div>
                                                    <div className="flex-horizontal mg-bottom-18px">
                                                        <input
                                                            type="number" pattern="^[0-9]+$"
                                                            inputMode="numeric" min="1"
                                                            className="w-commerce-commerceaddtocartquantityinput input add-cart-quantity"
                                                            defaultValue={count}
                                                            onChange={(e) => setCount(e.target.value)}
                                                        />
                                                        <div className="width-100" role="group">
                                                            <div role="group">
                                                                <div className="select-wrapper">
                                                                    <select className="select w-select" required=""
                                                                        onChange={(e) => setVariantSelected(e.target.value)}
                                                                    >
                                                                        {
                                                                            props.foodToCart.Variant.map((e, i) => (
                                                                                e.ISSHOW === 1 ?
                                                                                    <option key={e.PRICEID} value={i}>{e.VARIANT}</option>
                                                                                    :
                                                                                    ''
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-commerce-commercecartfooter cart-footer">
                                            <div>
                                                <button className="w-commerce-commercecartcheckoutbutton btn-primary"
                                                    onClick={() => addFoodToCartHandle()}
                                                    type="button"
                                                >
                                                    Xác nhận thêm
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="w-commerce-commercecartlist cart-list">
                                            <div className="inner-container _365px width-100 max-w-100-mbp">
                                                <div className="w-layout-grid grid-1-column">
                                                    <div className="w-richtext">
                                                        <blockquote>Quý Khách cần đăng ký tài khoản và tiến hành đăng nhập để có thể sử dụng tính năng giỏ món ăn, mong Quý Khách thông cảm vì điều này!</blockquote>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="buttons-row">
                                                <button className="w-commerce-commercecartcheckoutbutton btn-primary button-row w-button"
                                                    onClick={() => window.location = RouteString.loginRoute.route}
                                                    type="button"
                                                >
                                                    Đăng nhập
                                                </button>
                                                <button className="w-commerce-commercecartcheckoutbutton btn-secondary w-button"
                                                    onClick={() => window.location = RouteString.registerRoute.route}
                                                    type="button"
                                                >
                                                    Đăng ký tài khoản
                                                </button>
                                            </div>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div >
            :
            <></>
    )
}

export default MenuAddFood