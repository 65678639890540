import { Link } from 'react-router-dom'
import LogoQuanAn from '../Assets/image/LogoQuanAn.svg'
import React from 'react';

const Footer = () => {
    return (
        <footer className="footer-wrapper">
            <div className="container-default w-container">
                <div className="inner-container _792px-tablet center-element">
                    <div className="footer-top border-bottom">
                        <div className="w-layout-grid grid-footer-4-columns-v14">
                            <div
                                className="inner-container _310px"><Link to="/" aria-current="page"
                                    className="footer-logo-wrapper w-inline-block w--current"><img
                                        src={LogoQuanAn}
                                        alt="Quán ăn Cường Loan" className="footer-logo" />
                                </Link>
                                <div className="w-layout-grid social-media-grid-top">
                                    <a href="https://facebook.com/" 
                                        target="_blank" rel="noopener" className="social-icon-square w-inline-block">
                                        <div className="social-icon-font"></div>
                                    </a>
                                    <a href="https://www.instagram.com/" target="_blank" rel="noopener"
                                        className="social-icon-square w-inline-block">
                                        <div className="social-icon-font"></div>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div className="text-300 extra-bold nav-menu-title">Quý Khách có thể liên hệ với quán qua</div>
                                <div className="w-layout-grid grid-1-column">
                                    <a href="mailto:contact@qrcode.com"
                                        className="card-link-icon w-inline-block"><img
                                            src="https://assets.website-files.com/61e0a7ab0e57e953835f3aeb/61e1db57ba93c3247b488c4e_icon-2-footer-contact-qrcode-template.svg"
                                            alt="Email của quán ăn Cường Loan"
                                            className="square-icon mg-right-16px mg-bottom-16px-mb" />
                                        <div>
                                            <div className="text-200 color-neutral-600 mg-bottom-8px">Email:</div>
                                            <div className="text-200 extra-bold">quanancuongloan@gmail.com</div>
                                        </div>
                                    </a>
                                    <a href="tel:02993826498" className="card-link-icon w-inline-block"><img
                                        src="https://assets.website-files.com/61e0a7ab0e57e953835f3aeb/61e1db5777c6d866068825db_icon-1-footer-contact-qrcode-template.svg"
                                        alt="Điện thoại của quán ăn Cường Loan"
                                        className="square-icon mg-right-16px mg-bottom-16px-mb" />
                                        <div>
                                            <div className="text-200 color-neutral-600 mg-bottom-8px">Điện thoại:</div>
                                            <div className="text-200 extra-bold">0299.3826498</div>
                                        </div>
                                    </a>
                                    <a href="tel:0918084371" className="card-link-icon w-inline-block"><img
                                        src="https://assets.website-files.com/61e0a7ab0e57e953835f3aeb/61e1db5777c6d866068825db_icon-1-footer-contact-qrcode-template.svg"
                                        alt="Điện thoại của quán ăn Cường Loan"
                                        className="square-icon mg-right-16px mg-bottom-16px-mb" />
                                        <div>
                                            <div className="text-200 color-neutral-600 mg-bottom-8px">Điện thoại:</div>
                                            <div className="text-200 extra-bold">0918.084371</div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-w-id="f7873c90-4233-91b5-92e8-893e4b93c00f" className="footer-bottom">
                        <p className="mg-bottom-0">Copyright © Quán ăn Cường Loan</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer