/** @format */

import { createSlice } from "@reduxjs/toolkit";

const foodSlice = createSlice({
    name: "foods",
    initialState: {},
    reducers: {
        getAllFoodTypes: (state, { payload }) => ({
            ...state,
        }),
        getAllFoodTypesResult: (state, { payload }) => ({
            ...state,
            getAllFoodTypesResult: payload,
        }),
        getAllFoods: (state, { payload }) => ({
            ...state,
        }),
        getAllFoodsResult: (state, { payload }) => ({
            ...state,
            getAllFoodsResult: payload,
        }),
        getAllFoodsByFoodtype: (state, { payload }) => ({
            ...state,
        }),
        getAllFoodsByFoodtypeResult: (state, { payload }) => ({
            ...state,
            getAllFoodsByFoodtypeResult: payload,
        }),
        getFoodDetail: (state, { payload }) => ({
            ...state,
        }),
        getFoodDetailResult: (state, { payload }) => ({
            ...state,
            getFoodDetailResult: payload,
        }),
        getMenus: (state, { payload }) => ({
            ...state,
        }),
        getMenusResult: (state, { payload }) => ({
            ...state,
            getMenusResult: payload,
        }),
        getMenuDetail: (state, { payload }) => ({
            ...state,
        }),
        getMenuDetailResult: (state, { payload }) => ({
            ...state,
            getMenuDetailResult: payload,
        }),
    },
});

export const {
    getAllFoodTypes,
    getAllFoodTypesResult,
    getAllFoods,
    getAllFoodsResult,
    getAllFoodsByFoodtype,
    getAllFoodsByFoodtypeResult,
    getFoodDetail,
    getFoodDetailResult,
    getMenus,
    getMenusResult,
    getMenuDetail,
    getMenuDetailResult
} = foodSlice.actions;
export default foodSlice.reducer;
